<template>
	<section class="container">
		<div class="lay">
			Идёт загрузка...
		</div>
	</section>
</template>

<script>
export default {
	components: {},
	beforeCreate() {
		//this.$router.push('profile/login');
		this.$router.replace('/services');
	},
};
</script>
